@import '../stylesheets/actiontext.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

td > a {
    @apply font-bold text-gray-700
}

.devise-link {
    @apply font-medium text-gray-50 hover:text-gray-200;
}

.alert {
    @apply bg-gray-50 py-2 px-4 rounded-md border-2 text-gray-700 border-gray-500;
}

.alert-success {
    @apply bg-green-50 text-green-700 border-green-500;
}

.alert-warning {
    @apply bg-yellow-50 text-yellow-700 border-yellow-500;
}

.alert-danger {
    @apply bg-red-100 text-red-700 border-red-700;
}

.card {
    @apply bg-gray-100 rounded p-8 mt-4 max-w-sm w-full shadow-md;
}

.button {
    @apply rounded bg-gray-400 px-4 py-2 text-white font-medium hover:bg-gray-500;
}

.sidebar-item {
    @apply px-6 py-4 border-b text-gray-600 hover:bg-gray-300 hover:text-gray-600 focus:bg-gray-200 focus:text-gray-600 block font-bold;
}

.sidebar-item.active {
    @apply text-white bg-gray-400;
}

.button_to {
    @apply w-full;
}

.pagy-nav,
.pagy-nav-js {
    @apply flex flex-row rounded bg-white;
}

.pagy-nav .page a,
.pagy-nav .page.active,
.pagy-nav .page.prev.disabled,
.pagy-nav .page.next.disabled,
.pagy-nav-js .page a,
.pagy-nav-js .page.active,
.pagy-nav-js .page.prev.disabled,
.pagy-nav-js .page.next.disabled {
    @apply flex justify-center items-center px-3 py-1 h-10 min-w-10 text-gray-700 font-bold leading-tight overflow-hidden border-t border-b border-r border-gray-300 hover:bg-gray-700 hover:text-white hover:border-gray-700 active:bg-gray-700 active:text-white active:border-gray-700;
}

.pagy-nav .page.prev.disabled,
.pagy-nav .page.next.disabled,
.pagy-nav-js .page.prev.disabled,
.pagy-nav-js .page.next.disabled {
    @apply text-gray-300 cursor-not-allowed hover:bg-white hover:text-gray-300 hover:border-gray-300 active:bg-white active:text-gray-300 active:border-gray-300;
}

.pagy-nav .page.active,
.pagy-nav-js .page.active {
    @apply text-white bg-gray-700 cursor-not-allowed border-gray-700;
}

.pagy-nav .page.prev a,
.pagy-nav .page.prev.disabled {
    @apply rounded-l border-l;
}

.pagy-nav .page.next a,
.pagy-nav .page.next.disabled {
    @apply rounded-r;
}

.pagy-combo-nav-js {
    @apply flex max-w-max rounded-full px-3 py-1 text-sm text-gray-500 font-semibold bg-gray-300 shadow-md;
}

.pagy-combo-nav-js .pagy-combo-input {
    @apply bg-white px-2 rounded-sm;
}


.pagy-combo-nav-js .page.prev,
.pagy-combo-nav-js .page.next {
    @apply hover:text-gray-800 active:text-gray-800;
}

.pagy-combo-nav-js .page.prev.disabled,
.pagy-combo-nav-js .page.next.disabled {
    @apply text-gray-400 cursor-default;
}
