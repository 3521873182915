/*Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and*/
/*the trix-editor content (whether displayed or under editing). Feel free to incorporate this*/
/*inclusion directly in any other asset bundle and remove this file.*/
/*require trix/dist/trix*/
@import "trix/dist/trix.css";

/*We need to override trix.css’s image gallery styles to accommodate the*/
/*<action-text-attachment> element we wrap around attachments. Otherwise,*/
/*images in galleries will be squished by the max-width: 33%; rule.*/
trix-editor {
    min-height: 12rem;
}

.trix-content .attachment-gallery > action-text-attachment,
.trix-content .attachment-gallery > .attachment {
    flex: 1 0 33%;
    padding: 0 0.5em;
    max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2 > .attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > .attachment {
    flex-basis: 50%;
    max-width: 50%;
}

.trix-content action-text-attachment .attachment {
    padding: 0 !important;
    max-width: 100% !important;
}
